<template>
  <div class="yt-main exam_detail--style">
    <!--   面包屑   -->
    <el-breadcrumb class="yt-breadcrumb">
      <el-breadcrumb-item to="/manage/exam/list">考试管理</el-breadcrumb-item>
      <el-breadcrumb-item v-if="examineeType !== 'examinee'" to="/manage/exam/list">考试列表</el-breadcrumb-item>
      <el-breadcrumb-item v-if="examineeType !== 'examinee'" to="/manage/exam/result">考试结果</el-breadcrumb-item>
      <el-breadcrumb-item v-if="examineeType === 'examinee'" to="/manage/exam/examinee">考试考生</el-breadcrumb-item>
      <el-breadcrumb-item>{{ examineeType === 'examinee' ? '考题详情' : '题库数据' }}</el-breadcrumb-item>
    </el-breadcrumb>

    <!--   考试信息   -->
    <div class="yt-container yt-flex-layout">
      <div class="exam_left">
        <div class="yt-flex-layout exam_header">
          <div class="yt-flex-layout">
            <h3 class="exam_name">{{ paperName }}</h3>
            <div v-if="referenceTimes > 1" class="yt-flex-layout">
              <span
                :class="rIndex === selectRow ? 'selected_exam--info' : ''"
                @click="getOneExamInfo(rIndex)"
                class="span_unselected span_default yt-flex-layout"
                v-for="(r, rIndex) in referenceTimes"
                :key="rIndex"
              >
                {{ `第${getExamNum(rIndex + 1)}次` }}
                <img v-show="rIndex === selectRow" src="@/assets/examDetail/select_exam-detail.png" width="15" height="15" alt="" />
              </span>
            </div>
          </div>
          <div>
            <el-checkbox @change="filterQuestion(0)" v-model="isWrong">只看错题</el-checkbox>
            <!--            <el-checkbox @change="filterQuestion(2)" v-model="isReset">取消筛选</el-checkbox>-->
          </div>
        </div>

        <div class="exam_info yt-flex-layout">
          <div class="info_one--style yt-flex-layout" v-for="(l, lIndex) in infoList" :key="lIndex">
            <YTIcon :href="`#icon-${l.icon}`" :style="{ color: l.color }" />
            <span style="margin-right: 10px">{{ l.name }}:</span>
            <span v-if="l.key.includes('Time')">{{ $formatTime(l.value, 'yyyy-MM-dd') }}</span>
            <span v-else>{{ l.value }}{{ l.company }}</span>
          </div>
        </div>

        <div class="exam_question">
          <div class="big_question--type" v-for="(q, qIndex) in questionList" :key="qIndex">
            <p class="big-title yt-flex-layout" v-show="q.questionVOS.length">
              {{ `${q.name}` }}
              <span>{{ q.questionVOS.length }}</span>
              题，共
              <span>{{ q.totalTypeScore }}</span>
              分
            </p>

            <!--      展示各类型的题目        -->
            <div
              class="question_info"
              @mouseenter="selectNumById = vo.question.id"
              :id="`num-${vo.question.id}`"
              v-for="(vo, vIndex) in q.questionVOS"
              :key="vIndex"
            >
              <div class="stem yt-flex-layout">
                <span>{{ vIndex + 1 }}、</span>
                <div v-html="cleanWord(vo.question.stem)"></div>
              </div>
              <!--      判断题        -->
              <div style="margin-top: 12px" v-if="q.questionType === 0">
                <TrueOrFalse :question="vo.question" />
                <!--                <el-radio :disabled="!vo.question.answer" v-model="vo.question.answer" :label="true">正确</el-radio>-->
                <!--                <el-radio :disabled="vo.question.answer" v-model="!vo.question.answer" :label="false">错误</el-radio>-->
              </div>
              <!--      单选题 || 多选题       -->
              <div style="margin-top: 12px" v-if="q.questionType === 1 || q.questionType === 2">
                <div class="yt-flex-layout" v-for="(op, oIndex) in vo.question.options" :key="oIndex">
                  <span>{{ String.fromCharCode(oIndex + 65) }}、</span>
                  <span v-html="op.content" />
                </div>
              </div>
              <!--      考生答案及题目答案  resultStatus为0是考生答案正确      -->
              <div style="margin-top: 20px">
                <div class="analysis_style" :style="{ color: !vo.resultStatus && vo.myAnswer ? '#06C281' : '#ea4e18' }">
                  考生答案:<span style="margin-left: 3px" v-if="q.questionType !== 5 || !vo.myAnswer"
                    >{{ vo.myAnswer || '暂未作答' }} {{ vo.resultStatus && vo.myAnswer ? `(${resultType[vo.resultStatus]})` : null }}</span
                  >
                  <template v-else>
                    <MonacoEditor style="min-height: 200px" :editorId="vo.questionId + vIndex" :value="vo.myAnswer" :readOnly="true" :language="''" />
                  </template>
                </div>
                <div v-if="vo.question.answer !== undefined" style="color: #06C281;margin-top: 12px" class="analysis_style yt-flex-layout">
                  标准答案:<span style="margin: 0 0 0 3px" v-html="vo.question.answer" />
                </div>
              </div>

              <!--         解析  analysis     -->
              <div v-if="vo.question.analysis" class="analysis_style yt-flex-layout" style="margin-top: 12px">
                <span class="first-span">解析:</span>
                <span v-html="cleanWord(vo.question.analysis)" />
              </div>

              <!--         知识点  knowledgePoints     -->
              <div
                v-if="vo.question.knowledgePoints && vo.question.knowledgePoints.length"
                style="margin-top: 12px"
                class="tags_style yt-flex-layout"
              >
                <span>知识点:</span>
                <span v-for="(k, kIndex) in vo.question.knowledgePoints" :key="kIndex">{{ k.knowledgeName }}</span>
              </div>

              <!--         题目技能  abilityList     -->
              <div v-if="vo.question.abilityList && vo.question.abilityList.length" style="margin-top: 12px" class="tags_style yt-flex-layout">
                <span>技能:</span>
                <span v-for="(k, kIndex) in vo.question.abilityList" :key="kIndex">{{ k.abilityName }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="exam_right">
        <!--   右侧筛选     -->
        <div class="filter_header yt-flex-layout">
          <p :class="!filterType ? 'selected_filter' : ''" class="filter_default" @click="filterType = 0">题目类型</p>
          <p :class="filterType ? 'selected_filter' : ''" class="filter_default" @click="filterType = 1">题目技能</p>
        </div>
        <!--   题目类型筛选     -->
        <div v-show="!filterType">
          <div class="row_one--type yt-flex-layout" v-for="(q, qIndex) in questionList" :key="qIndex">
            <div class="title yt-flex-layout" v-show="q.questionVOS.length">
              <div class="blue_tip" />
              <span>{{ q.name }}</span>
            </div>
            <div class="question_number" v-show="q.questionVOS.length">
              <div
                @click="clickToOrder(vo)"
                :class="selectNumById === vo.question.id ? 'select_one--style' : ''"
                class="number_one"
                :style="{ color: vo.resultStatus === 1 || (vo.myPoints === 0 && !isWrong) ? '#DA2E2E' : '' }"
                v-for="(vo, vIndex) in q.questionVOS"
                :key="vIndex"
              >
                {{ vIndex < 9 ? `0${vIndex + 1}` : vIndex + 1 }}
              </div>
            </div>
          </div>
        </div>
        <!--   题目技能筛选     -->
        <div v-show="filterType">
          <el-tree class="ability_tree--style" :highlight-current="true" node-key="id" :data="abilityTree" @node-click="handleNodeClick">
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>{{ node.label }}</span>
              <span v-if="data.id !== 0">{{ data.questionCount }}</span>
            </span>
          </el-tree>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import examApi from '@api/exam'
import MonacoEditor from '@components/common/MonacoEditor'
import TrueOrFalse from '@components/manage/question/list/questions/TrueOrFalse'
export default {
  name: 'NewExamDetail',
  components: { MonacoEditor, YTIcon, TrueOrFalse },
  data() {
    return {
      resultType: ['正确', '错误', '未做', '不全对', '待批阅'],
      formatNumber: ['一', '二', '三'],
      examineeType: '',
      referenceTimes: 0,
      filterType: 0,
      isWrong: false, // 是否只看错题
      isReset: false, // 重置筛选
      selectNumById: null,
      paperName: null,
      selectRow: 0,
      abilityTree: [],
      infoList: [
        {
          name: '姓名',
          key: 'userName',
          icon: 'user-fill',
          color: '#7CAEEF',
          value: null,
          company: ''
        },
        {
          name: '岗位',
          key: 'postName',
          icon: 'gangwei',
          color: '#7CAEEF',
          value: null,
          company: ''
        },
        {
          name: '考试时间',
          key: 'startTime',
          icon: 'time',
          color: '#7CAEEF',
          value: null,
          company: ''
        },
        {
          name: '结束时间',
          key: 'submitTime',
          icon: 'time',
          color: '#7CAEEF',
          value: null,
          company: ''
        },
        {
          name: '用时',
          key: 'duration',
          color: '#FFBB00',
          icon: 'shijian2',
          value: null,
          company: '分钟'
        },
        {
          name: '得分',
          key: 'score',
          color: '#FF5050',
          icon: 'pingjunfenshu',
          value: null,
          company: '分'
        }
      ],
      questionList: [],
      copyQuestion: [], // 题目信息拷贝  深拷贝
      sendExamInfo: null
    }
  },
  mounted() {
    let res = this.$handleParams('newExamDetail')
    if (!res.IsAnswerExam) {
      // this.getExam(res)
    } else {
      // 考试报告跳转过来的
      this.paperName = res.name
      this.examineeType = res.type
      this.getPersonalExam(res)
    }
  },
  methods: {
    getExamNum(index) {
      // 获取考试次数
      let baseNum = ['一', '二', '三', '四', '五', '六', '七', '八', '九']
      if (index < 10) {
        return baseNum[index - 1]
      }
    },
    handleNodeClick(row) {
      // 树  点击事件
      let a = []
      if (row.id === 0) return this.filterQuestion(2, [])
      if (row.children && row.children.length) {
        a = row.children.map(c => {
          return c.id // push click parent tree data
        })
      } else {
        a.push(row.id) // push children tree data
      }
      this.filterQuestion(1, a)
    },
    filterQuestion(order, arr) {
      // 题目筛选
      //  order  0 只看错题
      //  order  1 技能筛选
      //  order  2 重置筛选
      // 深拷贝题目信息  避免修改污染所有数据
      this.questionList = JSON.parse(JSON.stringify(this.copyQuestion))
      if (order === 2) {
        this.isWrong = false
        return
      } else {
        this.isReset = false
      }
      if (order === 0 && this.isWrong) {
        this.questionList.forEach(q => {
          q.questionVOS = q.questionVOS.filter(vo => vo.resultStatus !== 0 || vo.resultStatus === undefined)
        })
        this.questionList = this.questionList.filter(list => list.questionVOS && list.questionVOS.length)
      }
      if (order === 1) {
        this.isWrong = false
        let a = []
        this.questionList.forEach(q => {
          q.questionVOS = q.questionVOS
            .map(vo => {
              let a
              if (vo.question.abilityList && vo.question.abilityList.length) {
                arr.forEach(ar => {
                  let find = vo.question.abilityList.findIndex(ab => ar === ab.abilityId)
                  if (find !== -1) {
                    a = vo
                  }
                })
              }
              return a // return vo
            })
            .filter(vo => vo) // delete undefined
        })
        this.questionList = this.questionList.filter(list => list.questionVOS && list.questionVOS.length) // filter questionVOS = []
      }
    },
    clickToOrder(row) {
      // 点击题号跳转指定题目位置
      this.selectNumById = row.question.id
      document.getElementById(`num-${row.question.id}`).scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      })
    },
    getOneExamInfo(index) {
      // 获取某场考试的信息
      this.selectRow = index
      // 获取第N次考试的信息
      this.sortInfo(index)
    },
    getPersonalExam({ userId, examId }) {
      examApi.getPersonExamByExamId(userId, examId).then(res => {
        this.referenceTimes = res.res.length // 总共考过几次
        this.sendExamInfo = res.res.reverse()
        if (!res.res.length) return
        // 默认获取第一次考试的信息
        this.sortInfo(0)
      })
    },
    sortInfo(index) {
      let firstInfo = this.sendExamInfo[index]
      this.infoList.forEach(l => {
        l.value = firstInfo[l.key]
      })
      this.getExamContentInfo(firstInfo['answerPaperId'])
      // 获取技能数数据
      this.getAbilityTree(firstInfo['answerPaperId'])
    },
    getAbilityTree(answerPaperId) {
      // 技能点数结构数据
      examApi.abilityTreeData(answerPaperId).then(res => {
        this.abilityTree = res.res.map(e => {
          return {
            id: e.abilityId,
            label: e.abilityName,
            questionCount: e.questionCount || 0,
            children:
              e.examAbilityList && e.examAbilityList.length
                ? e.examAbilityList.map(vo => {
                    return {
                      id: vo.abilityId,
                      label: vo.abilityName,
                      questionCount: vo.questionCount || 0
                    }
                  })
                : []
          }
        })
        this.abilityTree.unshift({
          id: 0,
          label: '所有技能',
          questionCount: 0,
          children: []
        })
      })
    },
    getExamContentInfo(id) {
      // 获取本次考试的信息
      // 试卷标号 => paperId 试卷信息 => sectionVOS
      examApi.getExamResSection(id).then(res => {
        if (Array.isArray(res.res) && res.res.length) {
          res.res.forEach(r => {
            switch (r.name) {
              case '判断题': {
                r['questionType'] = 0
                break
              }
              case '单选题': {
                r['questionType'] = 1
                break
              }
              case '多选题': {
                r['questionType'] = 2
                break
              }
              case '填空题': {
                r['questionType'] = 3
                break
              }
              case '简答题': {
                r['questionType'] = 4
                break
              }
              case '代码题': {
                r['questionType'] = 5
                break
              }
            }
            r['totalTypeScore'] = r.questionVOS.reduce((pre, next) => {
              return (pre += next.points || 0) // 统计每种题型的总分
            }, 0)
            // 当题型是单选或多选的时候  考生的答案转成字母
            r.questionVOS.forEach(vo => {
              if (!vo.hasOwnProperty('myAnswer')) return
              if (!vo.question.knowledgePoints) {
                vo.question.knowledgePoints = []
              }
              if (r['questionType'] === 1) {
                // 单选题
                let find = vo.question.options.findIndex(o => o.content === vo.myAnswer) // 查找考生的答案
                let find1 = vo.question.options.findIndex(o => o.correct) // 查找正确答案
                find !== -1 ? (vo['myAnswer'] = String.fromCharCode(find + 65)) : (vo['myAnswer'] = null)
                find1 !== -1 ? (vo.question['answer'] = String.fromCharCode(find1 + 65)) : (vo['answer'] = null)
              }
              if (r['questionType'] === 2) {
                // 多选题
                let arr = vo.myAnswer ? JSON.parse(vo.myAnswer) : []
                vo.question['answer'] = []
                arr.forEach((vm, vIndex) => {
                  let find = vo.question.options.findIndex(o => o.content === vm) // 查找考生的答案
                  find !== -1 ? (arr[vIndex] = String.fromCharCode(find + 65)) : vm
                })
                // 查找正确答案
                vo.question['answer'] = vo.question.options
                  .map((op, oIndex) => {
                    if (op.correct) {
                      return String.fromCharCode(oIndex + 65)
                    }
                  })
                  .filter(item => item)
                vo.myAnswer = arr.sort().join('、')
                vo.question['answer'] = Array.from(new Set(vo.question['answer'].sort())).join('、')
              }
              if (r['questionType'] === 3) {
                // 填空题
                // {"__1__":["叽叽"],"__2__":["jiji"]} 填空题考生答案的格式
                // 标准答案的格式是array
                vo.myAnswer = JSON.parse(vo.myAnswer)
                let myAnswer = []
                Object.keys(vo.myAnswer).forEach(vos => {
                  myAnswer.push(vo.myAnswer[vos][0])
                })
                vo.myAnswer = myAnswer.join('、')
                let answer = []
                answer = vo.question['blanks'].map(b => {
                  return b.blankAnswer[0]
                })
                vo.question['answer'] = answer.join('、')
              }
              if (r['questionType'] === 5) {
                // 代码题
                // 考生的答案是JSON格式
                // console.log(vo)
                vo.myAnswer = JSON.parse(vo.myAnswer)
                vo.myAnswer = vo.myAnswer.answer
              }
            })
          })
          this.copyQuestion = this.questionList = res.res
          // this.judgeExamType(this.questionList)
        }
      })
    }
    // judgeExamType(arr) {
    //   //
    // }
  }
}
</script>

<style lang="less" scoped>
.exam_detail--style {
  height: 100%;
  .font(12px, Regular, #000);
  .yt-container {
    height: calc(100% - 20px);
  }
  .exam_left {
    overflow: hidden;
    width: calc(100% - 260px);
    padding: 20px 0;
    .exam_header {
      padding: 0 20px;
      justify-content: space-between;
      align-items: center;
      .exam_name {
        margin-right: 20px;
      }
      .span_default {
        border: 1px solid #d9d9d9;
        position: relative;
        img {
          position: absolute;
          right: -1px;
          bottom: -1px;
        }
      }
      .span_unselected {
        cursor: pointer;
        align-items: center;
        .font(12px, Regular, #999);
        padding: 0 15px;
        border-radius: 4px;
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
      .selected_exam--info {
        border: 1px solid #448bff;
        color: #448bff;
      }
    }
    .exam_info {
      padding: 0 20px;
      margin: 20px 0;
      .info_one--style {
        color: #666;
        align-items: center;
        svg {
          margin-right: 6px;
          .wh(20px, 20px);
        }
        &:not(:last-child) {
          margin-right: 40px;
        }
      }
    }
    .exam_question {
      height: calc(100% - 77px);
      overflow-y: auto;
      .big-title {
        color: #282c3d;
        padding: 0 20px;
        align-items: center;
        height: 50px;
        background: #f0f0f0;
        span {
          margin: 0 5px;
          font-size: 16px;
          color: #448bff;
        }
      }
      .question_info {
        padding: 20px 20px 23px;
        ::v-deep .ivu-radio-wrapper {
          margin-right: 40px;
        }
        &:last-child {
          padding-bottom: 20px !important;
        }
        .stem {
          font-size: 12px;
          overflow: hidden;
          ::v-deep img {
            max-width: calc(100% - 30px) !important;
            height: auto;
          }
        }
        .analysis_style {
          white-space: nowrap;
          overflow: hidden;
          .first-span {
            margin-right: 10px;
          }
          span:last-child {
            white-space: normal;
          }
        }
        .tags_style {
          display: flex;
          white-space: nowrap;
          align-items: center;
          flex-wrap: wrap;
          span {
            margin-bottom: 10px;
            &:first-child {
              margin-right: 10px;
            }
            &:not(:first-child) {
              margin-right: 20px;
              padding: 3px 6px;
              background: #edf4ff;
              color: #448bff;
            }
          }
        }
      }
    }
  }
  .exam_right {
    width: 260px;
    margin-left: 10px;
    padding: 20px 10px;
    overflow-y: auto;
    .filter_header {
      height: 20px;
      border-radius: 36px;
      background-color: #ececec;
      .filter_default {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        .font(12px, Regular, #999);
      }
      .selected_filter {
        color: #fff;
        background-color: #438bff;
        border-radius: 36px;
      }
    }
    .row_one--type {
      flex-direction: column;
      margin-top: 20px;
      .title {
        align-items: center;
      }
      .blue_tip {
        .wh(3px, 10px);
        background-color: #438bff;
        margin-right: 3px;
      }
      .question_number {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        .number_one {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 10px;
          font-size: 12px;
          border: 1px solid #d9d9d9;
          .wh(24px, 24px);
          border-radius: 3px;
          cursor: pointer;
          &:hover {
            border: 1px solid #438bff;
            background-color: #438bff;
            color: #fff;
          }
        }
        .select_one--style {
          border: 1px solid #438bff;
          background-color: #438bff;
          color: #fff;
        }
      }
    }
  }
  .exam_left,
  .exam_right {
    background: #fff;
    border-radius: 6px 0px 0px 6px;
  }
}
::v-deep {
  .el-radio {
    margin-right: 50px;
  }
  .el-radio__inner {
    background: #f0f2f3;
    border-color: #bfc1c6;
  }
  .el-radio__label {
    font-size: @small;
    color: #000000 !important;
  }
  .is-checked {
    .el-radio__inner {
      background-color: #448bff;
      &:after {
        display: none;
      }
    }
  }
  .ability_tree--style {
    .font(12px, Regular, #000);
    margin-top: 12px;
    .custom-tree-node {
      flex: 1;
      display: flex;
      justify-content: space-between;
    }
    .el-tree-node__expand-icon {
      color: #282c3d;
    }
    .el-tree-node__content {
      padding-right: 10px;
      display: flex;
      height: 29px !important;
      .custom-tree-node {
        span:first-child {
          font-weight: bold;
        }
      }
    }
    .el-tree-node__children {
      .is-current {
        .el-tree-node__expand-icon {
          color: #fff;
        }
        background-color: #edf4ff;
        color: #448bff;
      }
      .el-tree-node {
        height: 29px !important;
      }
      .el-tree-node__expand-icon {
        color: #fff;
      }
      .custom-tree-node {
        span:first-child {
          font-weight: 400;
        }
      }
    }
  }
  .Bash {
    xmp {
      white-space: normal;
    }
  }
}
</style>
